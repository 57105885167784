html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Outfit;
  font-size: 14px;
  line-height: 1;
}

html {
  background: linear-gradient(90deg, #2b5876 0%, #281f48 128.79%);
}

html.light-bg {
  background: linear-gradient(88deg, #e0eafc -124.49%, #fff 227.99%);
}

h1, h2, h3, h4, h5, h6, p {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  font-family: Outfit;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .container {
    width: 750px;
  }
}

@media (width >= 992px) {
  .container {
    width: 970px;
  }
}

@media (width >= 1200px) {
  .container {
    width: 1110px;
  }
}

.wide-container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .wide-container {
    width: 750px;
  }
}

@media (width >= 992px) {
  .wide-container {
    width: 970px;
  }
}

@media (width >= 1200px) {
  .wide-container {
    width: 1293px;
  }
}

.x-wide-container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .x-wide-container {
    width: 750px;
  }
}

@media (width >= 992px) {
  .x-wide-container {
    width: 970px;
  }
}

@media (width >= 1200px) {
  .x-wide-container {
    width: 1383px;
  }
}

.narrow-container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .narrow-container {
    width: 750px;
  }
}

@media (width >= 992px) {
  .narrow-container {
    width: 970px;
  }
}

@media (width >= 1200px) {
  .narrow-container {
    width: 925px;
  }
}

svg.swiper-button-disabled {
  opacity: .47;
}

.rating-input {
  display: none;
}

.rating:hover .rating-star:hover, .rating:hover .rating-star:hover ~ .rating-star, .rating-input:checked ~ .rating-star {
  background-position: 0 0;
}

.rating-star, .rating:hover .rating-star {
  cursor: pointer;
  float: right;
  background: url("stars.94d47fea.svg") 0 -38px;
  width: 37px;
  height: 37px;
  margin-right: 3px;
  display: block;
}

.category-sort-by {
  color: #000;
  width: 236px;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/*# sourceMappingURL=index.86e9c364.css.map */
