html,
body {

    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: Outfit;
    line-height: 1
}

html {
    background: linear-gradient(90deg, #2B5876 0%, #281F48 128.79%);
}

html.light-bg {
    background: linear-gradient(88deg, #E0EAFC -124.49%, #FFF 227.99%);
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    /* font: inherit; */
    vertical-align: baseline;
}

* {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

button {
    font-family: Outfit;
    cursor: pointer;
}

.container {
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1110px;
    }
}

.wide-container {
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .wide-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .wide-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .wide-container {
        width: 1293px;
    }
}

.x-wide-container {
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .x-wide-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .x-wide-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .x-wide-container {
        width: 1383px;
    }
}

.narrow-container {
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .narrow-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .narrow-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .narrow-container {
        width: 925px;
    }
}

svg.swiper-button-disabled {
    opacity: 0.47;
}

.rating-input {
    display: none;
}

.rating:hover .rating-star:hover,
.rating:hover .rating-star:hover~.rating-star,
.rating-input:checked~.rating-star {
    background-position: 0 0;
}

.rating-star,
.rating:hover .rating-star {
    cursor: pointer;
    float: right;
    display: block;
    margin-right: 3px;
    width: 37px;
    height: 37px;
    background: url(./static/img/stars.svg) 0 -38px;
}

.category-sort-by {
    width: 236px;

    color: #000;

    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* .swiper-slide {
    opacity: 0 !important;
} */

/* .swiper-container {
    overflow: visible !important;
}

.swiper-slide-visible {
    opacity: 1 !important;
} */